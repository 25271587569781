<template>
  <div class="flexCol alignItems">
    <div class="content">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>商品管理</el-breadcrumb-item>
        <el-breadcrumb-item>秒杀商品</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="content_li">
        <div class="top-search">
          <div class="mt-10 flexRow justifyContentSpac">
            <div class="flexRow">
              <div class="flexRow alignItems margin-btn">
                <div class="search-title">商品名称：</div>
                <el-input v-model="search.commodityName" placeholder="搜索"></el-input>
              </div>
              <el-button type="primary" @click="searchData" style="margin-left:20px;">搜索</el-button>
              <el-button @click="initSearch" style="margin-left:20px;">重置</el-button>
            </div>

            <div class="flexRow">
              <el-button type="primary" v-if="seckillGoodsAdd" @click="goAdd">添加秒杀商品</el-button>
            </div>
          </div>
        </div>
        <div class="table-content">
          <el-table
              border
              :data="tableList"
              v-loading="tableLoading"
              style="width: 100%"
              :header-cell-style="this.utils.getRowClass">
            <el-table-column align="center" type="index" label="序号" width="80"></el-table-column>
            <el-table-column align="center" prop="commodityName" label="商品名称" min-width="150"
                             :show-overflow-tooltip="true"></el-table-column>
            <el-table-column align="center" label="商品图片">
              <template slot-scope="scope">
                <img @click="handleSee(scope.row.commodityImgs[0])" class="icon-img" :src="scope.row.commodityImgs[0]"/>
              </template>
            </el-table-column>
            <el-table-column align="center"  label="秒杀时间" :min-width="150">
              <template slot-scope="scope" >{{ scope.row.skStartTime === null ? '-':`${scope.row.skStartTime}至${scope.row.skEndTime}`}}</template>
            </el-table-column>
            <el-table-column align="center"  label="促销活动">
              <template slot-scope="scope" >{{ scope.row.promotionName === null || scope.row.promotionName === '' ? '-':scope.row.promotionName}}</template>
            </el-table-column>
            <el-table-column align="center" label="操作" :min-width="150">
              <template slot-scope="scope">
                <div class="flex flex_row flex_ju_center">
                  <div class="border_center_left" v-if="seckillGoodsUpd" @click="edit(scope.row)">编辑</div>
                  <div class="border_center_left" v-if="seckillGoodsDel" @click="del(scope.row)">删除</div>
                  <div class="border_center_left" @click="details(scope.row)">详情</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="flexRow justifyContentSpac" style="margin-top:20px">
            <div></div>
            <el-pagination
                @size-change="handleSizeChange"
                background
                layout="total, prev, pager, next,sizes"
                :total="total"
                :page-sizes="[10, 15, 20, 30, 50, 100]"
                :page-size="pageSize"
                @current-change="changePage"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogImgVisible">
      <div class="flexRow justifyContentCenter alignItems">
        <img width="70%" :src="dialogImgUrl" alt=""></div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: {
        commodityName: ''
      },
      tableList: [],
      value: "",
      tableLoading: false,
      btnLoading: false,
      seckillGoodsDel: false,
      seckillGoodsUpd: false,
      seckillGoodsAdd: false,
      total: 0,
      page: 1,
      pageSize: 10,
      dialogImgVisible: false,
      dialogImgUrl: "",
    };
  },
  mounted() {
    this.getMenuBtn();
    this.getData();
  },
  methods: {
    getData() {
      let _this = this;
      _this.tableLoading = true;
      const requestData = {
        page: _this.page,
        pageSize: _this.pageSize,
        search: {}
      }

      // 过滤筛选
      const filterData = JSON.parse(JSON.stringify(_this.search));
      for (let key in filterData) {
        if (filterData[key]) {
          requestData.search[key] = filterData[key];
        }
      }

      _this.$api
          .getSeckill(requestData)
          .then(res => {
            if (res.data.code == 200) {
              _this.total = res.data.data.total;
              _this.tableList = res.data.data.records;
              _this.tableList.forEach(item => {
                item.commodityImgs = item.commodityImgs.split(",");
              });
              _this.tableLoading = false;
            }
          }).catch(() => {
        _this.tableLoading = false;
      });
    },
    // 获取按钮权限
    getMenuBtn() {
      let _this = this;
      const list = JSON.parse(localStorage.getItem("jurisdictionList"));
      list.forEach((v) => {
        if (v == "seckillGoods:add") {
          _this.seckillGoodsAdd = true;
        }
        if (v == "seckillGoods:upd") {
          _this.seckillGoodsUpd = true;
        }
        if (v == "seckillGoods:del") {
          _this.seckillGoodsDel = true;
        }
      })
    },
    changePage(e) {
      let _this = this;
      _this.page = e;
      _this.getData();
    },
    handleSizeChange(e) {
      let _this = this;
      _this.page = 1;
      _this.pageSize = e;
      _this.getData();
    },
    initSearch() {
      let _this = this;
      _this.search.commodityName = "";
      _this.page = 1;
      _this.getData();
    },
    searchData() {
      let _this = this;
      _this.page = 1;
      _this.getData();
    },
    // 浏览图片
    handleSee(e) {
      let _this = this;
      _this.dialogImgUrl = e;
      _this.dialogImgVisible = true;
    },
    // 添加
    goAdd() {
      let _this = this;
      _this.$router.push("/addSeckillGoods?type=add");
    },
    details(e) {
      let _this = this;
      _this.$router.push(
          "/addSeckillGoods?type=details&data=" + encodeURIComponent(JSON.stringify(e))
      );
    },
    edit(e) {
      let _this = this;
      _this.$router.push(
          "/addSeckillGoods?type=edit&data=" + encodeURIComponent(JSON.stringify(e))
      );
    },
    // 删除
    del(row) {
      let _this = this;
      _this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info"
      })
          .then(() => {
            _this.$api.delSeckill({id:row.id}).then(res => {
              if (res.data.code == 200) {
                if (res.data.data) {
                  _this.$message.success('删除成功');
                  _this.getData();
                } else {
                  _this.$message.error('删除失败');
                }
              }
            });
          })
          .catch(() => {
          });
    },
  }
};
</script>
<style scoped lang="less">
.between-text {
  margin: 0 10px;
}

/deep/ .el-form-item {
  width: 100%;
}

/deep/ .el-tag {
  color: #fff !important;
  border: none;
}

.attrsAssociateImage {
  width: 50px;
  height: 50px;
}

.commodityName {
  margin-left: 10px;
  text-align: left;
}

.recipient {
  text-align: left;
}

.el-dropdown-link {
  cursor: pointer;
  margin-right: 10px;
}

.el-icon-arrow-down {
  font-size: 12px;
}

/deep/ .el-table__header-wrapper .el-checkbox {
  display: none
}
</style>

